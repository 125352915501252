import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Career = () => (
  <Layout>
    <SEO
      title="Flatart'ta Kariyer"
      description="Sunduğumuz hizmetler ve özel stratejilerin yansımalarını deneyimleyen müşterilerimizden bazılarına ait istatistikler."
    />
    <iframe
      src="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3"
      frameborder="0"
      width="100%"
      height="100%"
      style={{ minHeight: "100vh", marginTop:50 }}
    ></iframe>
  </Layout>
)

export default Career
